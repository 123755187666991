/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Trueno Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Regular'), url('TruenoRg.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Regular Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Regular Italic'), url('TruenoRgIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraLight'), url('TruenoUltLt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno UltraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraLight Italic'), url('TruenoUltLtIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Light';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Light'), url('TruenoLt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Light Italic'), url('TruenoLtIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno SemiBold'), url('TruenoSBd.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno SemiBold Italic'), url('TruenoSBdIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold'), url('TruenoBd.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold Italic'), url('TruenoBdIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Bold Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold Outline'), url('TruenoBdOl.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Bold Outline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Bold Outline Italic'), url('TruenoBdOlIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold'), url('TruenoExBd.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold Italic'), url('TruenoExBdIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno ExtraBold Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold Outline'), url('TruenoExBdOl.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno ExtraBold Outline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno ExtraBold Outline Italic'), url('TruenoExBdOlIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Black';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black'), url('TruenoBlk.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black Italic'), url('TruenoBlkIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Black Outline';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black Outline'), url('TruenoBlkOl.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno Black Outline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Black Outline Italic'), url('TruenoBlkOlIt.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno UltraBlack';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraBlack'), url('TruenoUltBlk.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Trueno UltraBlack Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno UltraBlack Italic'), url('TruenoUltBlkIt.woff') format('woff');
    }